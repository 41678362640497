var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "lg",
      "id": "stock-modal",
      "title": ("Stok " + (_vm.barang.nama))
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var cancel = _ref.cancel;
        return [_c('section', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return cancel();
            }
          }
        }, [_vm._v("Batal")]), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Selesai")])], 1)];
      }
    }])
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "bordered": "",
      "fields": _vm.fields,
      "items": _vm.stocks
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedStocks,
            callback: function ($$v) {
              _vm.selectedStocks = $$v;
            },
            expression: "selectedStocks"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref3) {
        var index = _ref3.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : '-') + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : '-') + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : '-') + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : '-') + " ")];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }