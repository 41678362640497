<template>
    <b-modal size="lg" id="stock-modal" :title="`Stok ${barang.nama}`">
        <b-table
            responsive
            bordered
            :fields="fields"
            :items="stocks"
        >
            <template #head(checkbox)>
                <b-form-checkbox v-model="allChecked"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="{item}">
                <b-form-checkbox v-model="selectedStocks" :value="item"></b-form-checkbox>
            </template>
            <template #cell(no)="{index}">
                {{ index + 1 }}
            </template>
            <template #cell(blok)="{item}">
                {{ item.blok ? item.blok.blok : '-' }}
            </template>
            <template #cell(palet)="{item}">
                {{ item.palet ? item.palet.palet : '-' }}
            </template>
            <template #cell(rak)="{item}">
                {{ item.rak ? item.rak.rak : '-' }}
            </template>
            <template #cell(laci)="{item}">
                {{ item.laci ? item.laci.laci : '-' }}
            </template>
        </b-table>
        <template #modal-footer="{cancel}">
            <section class="d-flex justify-content-end">
                <b-button variant="outline-danger" @click="cancel()">Batal</b-button>
                <b-button variant="warning" class="ml-2" @click.prevent="submit">Selesai</b-button>
            </section>
        </template>
    </b-modal>
</template>
<script>
    import { BTable, BFormCheckbox, BButton } from 'bootstrap-vue'
    export default {
        watch: {
            allChecked(val) {
                if(val) {
                    this.selectedStocks = this.stocks
                }
                else {
                    this.selectedStocks = []
                }
            }
        },
        components: {
            BTable, BFormCheckbox, BButton
        },
        props: ['barang', 'stocks', 'rincian_id'],
        data: () => ({
            allChecked: false,
            selectedStocks: [],
            fields: [
                {key: 'checkbox', label: '#'},
                {key: 'no', label: 'No'},
                {key: 'blok', label: 'Blok'},
                {key: 'palet', label: 'Palet'},
                {key: 'rak', label: 'Rak'},
                {key: 'laci', label: 'Laci'},
                {key: 'jumlah', label: 'Stok'}
            ]
        }),
        methods: {
            submit() {
                if(this.selectedStocks.length < 1) {
                    this.displayError({
                        message: 'Harap pilih stok'
                    })
                    return false
                }

                this.$emit('submit', {selectedStocks: this.selectedStocks, rincian_id: this.rincian_id})
            }
        }
    }
</script>